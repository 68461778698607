// import axios from '@axios'
import axiosIns from '@axios'

export default {
  namespaced: true,
  strict: false,
  state: {
    calendarOptions: [],
    selectedCalendars: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/data/meeting_calendars', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/data/meeting_calendars/saveData', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/data/meeting_calendars/saveData', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/data/meeting_calendars/deleteData', { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
