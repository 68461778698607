<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Toplantı Takvimi
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            v-if="formShow"
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Toplantı Adı"
              rules="required"
            >
              <b-form-group
                label="Toplantı Adı"
                label-for="event-title"
              >
                <b-form-input
                  id="event-title"
                  v-model="eventLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Toplantı Adı"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Calendar -->
            <validation-provider
              #default="validationContext"
              name="Toplantı Odası"
              rules="required"
            >

              <b-form-group
                label="Toplantı Odası"
                label-for="id_meeting_rooms"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.calendar"
                  :options="meetingRooms"
                  label="title"
                  :reduce="item => Number(item.id)"
                  input-id="id_meeting_rooms"
                  placeholder="Toplantı Odası"
                >

                  <template #option="{ color, title }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ title }}</span>
                  </template>

                  <template #selected-option="{ color, title }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ title }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="Başlangıç"
              rules="required"
            >

              <b-form-group
                label="Başlangıç"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.start"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'd.m.Y H:i', time_24hr: true}"
                  placeholder="Başlangıç"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              #default="validationContext"
              name="Bitiş"
              rules="required"
            >

              <b-form-group
                label="Bitiş"
                label-for="end-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.end"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'd.m.Y H:i', time_24hr: true}"
                  placeholder="Bitiş"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- All Day -->
            <b-form-group>
              <b-form-checkbox
                v-model="eventLocal.allDay"
                name="check-button"
                switch
                inline
              >
                Tüm Gün
              </b-form-checkbox>
            </b-form-group>
            <b-alert
              v-if="eventLocal.extendedProps.username"
              variant="warning"
              show
            >
              <div class="alert-body font-small-2 text-center">
                {{ eventLocal.extendedProps.username }}
              </div>
            </b-alert>
            <!-- Form Actions -->
            <div
              class="d-flex mt-2"
            >
              <b-button
                variant="primary"
                class="mr-2"
                type="submit"
                block
              >
                <FeatherIcon icon="SaveIcon" />
                {{ eventLocal.id ? 'Güncelle' : 'Kaydet ' }}
              </b-button>
              <b-button
                variant="outline-danger"
                class="text-nowrap"
                @click="$emit('remove-event'); hide();"
              >
                <FeatherIcon icon="TrashIcon" />
                Sil
              </b-button>
            </div>
          </b-form>
          <template v-else>
            <b-list-group class="m-2">
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Toplantı
                </div>
                <div>{{ eventLocal.title }}</div>
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Toplantı Odası
                </div>
                <div>{{ eventLocal.extendedProps.meeting_room }}</div>
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Başlangıç Tarihi
                </div>
                <div>{{ moment(eventLocal.start).format('LLLL') }}</div>
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Bitiş Tarihi
                </div>
                <div>{{ moment(eventLocal.end).format('LLLL') }}</div>
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Oluşturma
                </div>
                <div>{{ eventLocal.extendedProps.username }}</div>
              </b-list-group-item>
            </b-list-group>
          </template>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BButton, BFormInvalidFeedback, BAlert, BListGroup, BListGroupItem,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BAlert,
    BListGroup,
    BListGroupItem,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
  computed: {
    meetingRooms() {
      return this.$store.getters['meetingRooms/dataList']
    },
    formShow() {
      if (this.eventLocal.id) {
        if (this.userData.id === this.eventLocal.extendedProps.id_com_user) {
          return true
        }
      } else {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
